<template>
  <div>
    <div class="d-flex align-content-end justify-content-end">
      <b-row>
        <b-col lg="12">
          <div class="d-flex justify-content-end mb-2">
            <b-row :class="defStyleBorderRoi(roi_data)">
              <div
                :class="defStyleTextRoi(roi_data)"
                class="text-center d-flex align-items-center"
                style="
                  border: 0px solid transparent;
                  border-top-left-radius: 7px;
                  border-bottom-left-radius: 7px;
                "
              >
                <tabler-icon
                  :icon="roi_data < 60 ? 'AlertCircleIcon' : 'CircleCheckIcon'"
                  class="cursor-pointer mr-1"
                  size="18"
                  :style="defineColorIcon(roi_data)"
                /><span>ROI</span>
              </div>
              <h3
                v-if="roi_data != null"
                :style="defStylePercentRoi(roi_data)"
                :class="
                  prueba == 1
                    ? 'av-balance-content-blue'
                    : 'av-balance-content-red'
                "
                class="text-center"
              >
                {{ roi_data + " %" }}
              </h3>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col cols="2" class="mb-md-0 mb-2">
        <b-form-group label="From:" label-for="from">
          <kendo-datepicker
            id="date"
            v-model="filters.from"
            :format="'yyyy-MM-dd'"
            class="input-form"
            name="date"
          />
        </b-form-group>
      </b-col>
      <b-col cols="2" class="mb-md-0 mb-2">
        <b-form-group label="To:" label-for="to">
          <kendo-datepicker
            id="date"
            v-model="filters.to"
            :format="'yyyy-MM-dd'"
            class="input-form"
            name="date"
          />
        </b-form-group>
      </b-col>
      <b-col cols="2" class="mb-md-0 mb-2">
        <b-form-group label="Programs" label-for="from">
          <b-select
            v-model="filters.program"
            :options="filtersData.programs"
            value-field="id"
            text-field="label"
          />
        </b-form-group>
      </b-col>
      <b-col cols="2" class="mb-md-0 mb-2 mt-2">
        <b-button
          variant="primary"
          class="btn-icon"
          style="margin-right: 0.6rem"
          @click="getDataReports"
        >
          <feather-icon icon="SearchIcon" size="14" />
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="showMessage" style="margin: 70px !important">
      <span class="font-large-1 w-100 text-center"
        >There are no records to show</span
      >
    </b-row>
    <template v-else>
      <div
        v-for="(program, key) in juanData.programsData"
        :key="key"
        style="margin-bottom: 46px !important"
      >
        <CoolLightBox
          :items="itemImage"
          :index="showImage"
          @close="showImage = null"
        />

        <b-table-simple
          small
          sticky-header="40vh"
          responsive
          no-border-collapse
          hover
          bordered
          class="table-scroll-pere"
        >
          <b-thead>
            <b-tr>
              <b-th
                :colspan="!(moduleId === 2 || moduleId === 12) ? 19 : 23"
                class="text-center"
                :style="
                  isDarkSkin
                    ? 'background: #222222;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 15px 15px 0px 0px; height: 65px;'
                    : 'background: #EFEFEF;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 15px 15px 0px 0px;height: 65px;'
                "
              >
                <div
                  class="
                    font-medium-5
                    d-flex
                    align-items-center
                    justify-content-center
                    h-100
                  "
                  style="text-align: center"
                  :style="isDarkSkin ? 'color: #fff' : 'color:#606060'"
                >
                  <div style="height: 50px" class="mr-2">
                    <b-img
                      class="w-100 h-100"
                      :src="`${baseImg}/assets${logo(program.programName)}`"
                    />
                  </div>
                  <span>
                    {{ program.programName }}
                  </span>
                </div>
              </b-th>
            </b-tr>
            <b-tr>
              <b-th
                style="height: 50px"
                :colspan="!(moduleId === 2 || moduleId === 12) ? 3 : 5"
                class="text-center"
                :style="
                  isDarkSkin
                    ? 'background: #222222;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;\n' +
                      'color: #31EF8E'
                    : 'background: #31EF8E;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;color: #FFFFFF;'
                "
              >
                <div
                  class="
                    font-medium-2
                    d-flex
                    align-items-center
                    justify-content-center
                    h-100
                  "
                  style="text-align: center"
                >
                  Marketing
                </div>
              </b-th>
              <b-th
                style="height: 50px"
                colspan="9"
                class="text-center"
                :style="
                  isDarkSkin
                    ? 'background: #222222;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;\n' +
                      'color: #51A7F2'
                    : 'background: #51A7F2;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;color: #FFFFFF;'
                "
              >
                <div
                  class="
                    font-medium-2
                    d-flex
                    align-items-center
                    justify-content-center
                    h-100
                  "
                  style="text-align: center"
                >
                  Meta Media
                </div>
              </b-th>
              <b-th
                style="height: 50px"
                colspan="3"
                class="text-center"
                :style="
                  isDarkSkin
                    ? 'background: #222222;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;\n' +
                      'color: #F2C94B'
                    : 'background: #F2C94B;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;color: #FFFFFF;'
                "
              >
                <div
                  class="
                    font-medium-2
                    d-flex
                    align-items-center
                    justify-content-center
                    h-100
                  "
                  style="text-align: center"
                >
                  Filter
                </div>
              </b-th>
              <b-th
                style="height: 50px"
                :colspan="!(moduleId === 2 || moduleId === 12) ? 4 : 6"
                class="text-center"
                :style="
                  isDarkSkin
                    ? 'background: #222222;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;\n' +
                      'color: #F8A65B'
                    : 'background: #F8A65B;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;color: #FFFFFF;'
                "
              >
                <div
                  class="
                    font-medium-2
                    d-flex
                    align-items-center
                    justify-content-center
                    h-100
                  "
                  style="text-align: center"
                >
                  Seller
                </div>
              </b-th>
            </b-tr>
            <b-tr>
              <b-th
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #31EF8E'
                    : 'background: #9EF2C7;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
              >
                Date
              </b-th>
              <b-th
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #31EF8E'
                    : 'background: #9EF2C7;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
              >
                Flyer
              </b-th>
              <b-th
                v-if="moduleId === 2 || moduleId === 12"
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #31EF8E'
                    : 'background: #9EF2C7;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                Investment
              </b-th>
              <b-th
                v-if="moduleId === 2 || moduleId === 12"
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #31EF8E'
                    : 'background: #9EF2C7;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                CPA
              </b-th>

              <b-th
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #31EF8E'
                    : 'background: #9EF2C7;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                Replies
              </b-th>

              <b-th
                v-b-tooltip.hover.top="'Inconsistency'"
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #25B0FF'
                    : 'background: #8DCAFF;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                Inc
              </b-th>
              <b-th
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #25B0FF'
                    : 'background: #8DCAFF;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                Real Replies
              </b-th>
              <b-th
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #25B0FF'
                    : 'background: #8DCAFF;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                Replies
              </b-th>
              <b-th
                v-b-tooltip.hover.top="'Inconsistency'"
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #25B0FF'
                    : 'background: #8DCAFF;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                Inc
              </b-th>
              <b-th
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #25B0FF'
                    : 'background: #8DCAFF;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                Answers
              </b-th>
              <b-th
                v-b-tooltip.hover.top="'Inconsistency'"
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #25B0FF'
                    : 'background: #8DCAFF;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                Inc
              </b-th>
              <b-th
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #25B0FF'
                    : 'background: #8DCAFF;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                Mobile
              </b-th>
              <b-th
                v-b-tooltip.hover.top="'Inconsistency'"
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #25B0FF'
                    : 'background: #8DCAFF;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                Inc
              </b-th>
              <b-th
                v-b-tooltip.hover.top="'Appointment Filter'"
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #25B0FF'
                    : 'background: #8DCAFF;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                App Filter
              </b-th>
              <b-th
                v-b-tooltip.hover.top="'No Answer Call'"
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #F2C94B'
                    : 'background: #F5DD91;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                NO ANSWER CALL
              </b-th>
              <b-th
                v-b-tooltip.hover.top="'Inconsistency'"
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #F2C94B'
                    : 'background: #F5DD91;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                Inc
              </b-th>
              <b-th
                v-b-tooltip.hover.top="'Appointment Seller'"
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #F2C94B'
                    : 'background: #F5DD91;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                App Seller
              </b-th>
              <b-th
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #F8A65B'
                    : 'background: #FFDBBB;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                APP SELLER
              </b-th>
              <b-th
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #F8A65B'
                    : 'background: #FFDBBB;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                Sale
              </b-th>
              <b-th
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #F8A65B'
                    : 'background: #FFDBBB;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                No Answer Call
              </b-th>
              <b-th
                v-b-tooltip.hover.top="'Inconsistency'"
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #F8A65B'
                    : 'background: #FFDBBB;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                Inc
              </b-th>
              <b-th
                v-if="moduleId === 2 || moduleId === 12"
                v-b-tooltip.hover.top="'Initial Payment'"
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #F8A65B'
                    : 'background: #FFDBBB;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                IP
              </b-th>
              <b-th
                v-if="moduleId === 2 || moduleId === 12"
                style="text-align: center"
                :style="
                  isDarkSkin
                    ? 'color: #F8A65B'
                    : 'background: #FFDBBB;\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                ROI
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(tableItem, index) in program.tableData" :key="index">
              <!--            marketing -->
              <b-td
                style="
                  width: 17rem;
                  text-align: center;
                  height: 63px;
                  font-weight: bolder;
                "
              >
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                  style="text-align: center; font-size: 15px"
                >
                  {{
                    tableItem.mkt_date[tableItem.mkt_date.length - 1]
                      | myGlobal
                  }}-
                  {{ tableItem.mkt_date[0] | myGlobal }}
                </div>
              </b-td>
              <b-td style="width: 7rem; text-align: center">
                <div
                  v-b-tooltip.hover.left="'Click to view flyer'"
                  class="
                    d-flex
                    justify-content-center
                    align-items-start
                    clickable
                  "
                  @click="openImage(index, key)"
                >
                  <b-avatar
                    square
                    size="50"
                    v-bind="mainProps"
                    :src="tableItem.mkt_flyer_link"
                    rounded="lg"
                    icon="file-earmark-image-fill"
                  />
                </div>
              </b-td>
              <b-td
                v-if="moduleId === 2 || moduleId === 12"
                style="height: 63px; width: 120px"
                class="text-center"
              >
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                  style="text-align: center; font-size: 15px"
                >
                  $ {{ tableItem.mkt_investment | currency }}
                </div>
              </b-td>
              <b-td
                v-if="moduleId === 2 || moduleId === 12"
                style="height: 63px; width: 120px"
                class="text-center"
              >
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                  style="text-align: center; font-size: 15px"
                >
                  $ {{ tableItem.mkt_cpa | currency }}
                </div>
              </b-td>
              <b-td style="height: 63px; width: 120px" class="text-center">
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                  style="text-align: center; font-size: 15px"
                >
                  {{ tableItem.mkt_replies }}
                </div>
              </b-td>
              <!--            meta media -->

              <b-td
                style="height: 63px;  width: 120px  font-weight: bolder; width: 70px"
                :style="
                  isDarkSkin
                    ? tableItem.mkt_inc_replies > 0
                      ? 'background: rgba(255, 100, 100, 0.6);\n' +
                        'border: 1px solid rgb(179 172 172 / 59%);\n' +
                        'border-radius: 0px; '
                      : 'background: rgba(191, 225, 255, 0.4);\n' +
                        'border: 1px solid rgb(179 172 172 / 59%);\n' +
                        'border-radius: 0px;'
                    : tableItem.mkt_inc_replies > 0
                    ? 'background: rgba(255, 100, 100, 0.6);\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;  '
                    : 'background: rgba(191, 225, 255, 0.4);\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'color: #007CC2;\n' +
                      'border-radius: 0px;'
                "
                class="text-center"
              >
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                  style="text-align: center; font-size: 16px"
                >
                  <template v-if="tableItem.mkt_inc_replies >= 0">
                    {{ tableItem.mkt_inc_replies }}
                  </template>
                  <template v-else> - </template>
                </div>
              </b-td>
              <b-td
                style="height: 63px; width: 120px"
                class="text-center"
                @click="openLeadsValueByFlyerId(tableItem.mkt_id_flyer)"
              >
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                  style="text-align: center; font-size: 15px"
                >
                  {{ tableItem.sn_multiply }}
                </div>
              </b-td>
              <b-td style="height: 63px; width: 120px" class="text-center">
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                  style="text-align: center; font-size: 15px"
                >
                  {{ tableItem.sn_replies }}
                </div>
              </b-td>
              <b-td
                :style="
                  isDarkSkin
                    ? tableItem.sn_rep_ans_inc > 0
                      ? 'background: rgba(255, 100, 100, 0.6);\n' +
                        'border: 1px solid rgb(179 172 172 / 59%);\n' +
                        'border-radius: 0px; '
                      : 'background: rgba(191, 225, 255, 0.4);\n' +
                        'border: 1px solid rgb(179 172 172 / 59%);\n' +
                        'border-radius: 0px;'
                    : tableItem.sn_rep_ans_inc > 0
                    ? 'background: rgba(255, 100, 100, 0.6);\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px; color: #FB0000;'
                    : 'background: rgba(191, 225, 255, 0.4);\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'color: #007CC2;\n' +
                      'border-radius: 0px;'
                "
                class="text-center headerC"
                @click="openListMotivesModal(1, tableItem.mkt_id_flyer)"
              >
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    h-100
                    iconFather
                  "
                >
                  <span>{{ tableItem.sn_rep_ans_inc }}</span>
                  <tabler-icon
                    :style="
                      isDarkSkin
                        ? tableItem.sn_rep_ans_inc > 0
                          ? 'color: rgb(227 129 129)'
                          : 'color: rgb(151 211 245)'
                        : tableItem.sn_rep_ans_inc > 0
                        ? 'color: #FF0000'
                        : 'color: #007CC2'
                    "
                    :icon="
                      tableItem.sn_rep_ans_inc > 0
                        ? 'AlertCircleIcon'
                        : 'CircleCheckIcon'
                    "
                    class="cursor-pointer iconChild"
                    size="18"
                    @click="openListMotivesModal(1, tableItem.mkt_id_flyer)"
                  />
                </div>
              </b-td>
              <b-td style="height: 63px; width: 120px" class="text-center">
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                  style="text-align: center; font-size: 15px"
                >
                  {{ tableItem.sn_answers }}
                </div>
              </b-td>
              <b-td
                style="height: 63px;  width: 120px  font-weight: bolder"
                :style="
                  isDarkSkin
                    ? tableItem.sn_ans_mob_inc > 0
                      ? 'background: rgba(255, 100, 100, 0.6);\n' +
                        'border: 1px solid rgb(179 172 172 / 59%);\n' +
                        'border-radius: 0px; '
                      : 'background: rgba(191, 225, 255, 0.4);\n' +
                        'border: 1px solid rgb(179 172 172 / 59%);\n' +
                        'border-radius: 0px;'
                    : tableItem.sn_ans_mob_inc > 0
                    ? 'background: rgba(255, 100, 100, 0.6);\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px;color: #FB0000; '
                    : 'background: rgba(191, 225, 255, 0.4);\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'color: #007CC2;\n' +
                      'border-radius: 0px;'
                "
                class="text-center headerC"
              >
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    h-100
                    iconFather
                  "
                  @click="openListMotivesModal(2, tableItem.mkt_id_flyer)"
                >
                  <span>{{ tableItem.sn_ans_mob_inc }}</span
                  ><tabler-icon
                    :icon="
                      tableItem.sn_ans_mob_inc > 0
                        ? 'AlertCircleIcon'
                        : 'CircleCheckIcon'
                    "
                    style="margin-left: 0.2rem"
                    :style="
                      isDarkSkin
                        ? tableItem.sn_ans_mob_inc > 0
                          ? 'color: rgb(227 129 129)'
                          : 'color: rgb(151 211 245)'
                        : tableItem.sn_ans_mob_inc > 0
                        ? 'color: #FF0000'
                        : 'color: #007CC2'
                    "
                    class="cursor-pointer iconChild"
                    size="16"
                    @click="openListMotivesModal(2, tableItem.mkt_id_flyer)"
                  />
                </div>
              </b-td>
              <b-td style="height: 63px; width: 120px" class="text-center">
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                  style="text-align: center; font-size: 15px"
                >
                  {{ tableItem.sn_mobile }}
                </div>
              </b-td>
              <b-td
                style="height: 63px;  width: 120px  font-weight: bolder"
                :style="
                  isDarkSkin
                    ? tableItem.sn_mob_filter_inc > 0
                      ? 'background: rgba(255, 100, 100, 0.6);\n' +
                        'border: 1px solid rgb(179 172 172 / 59%);\n' +
                        'border-radius: 0px; '
                      : 'background: rgba(191, 225, 255, 0.4);\n' +
                        'border: 1px solid rgb(179 172 172 / 59%);\n' +
                        'border-radius: 0px; '
                    : tableItem.sn_mob_filter_inc > 0
                    ? 'background: rgba(255, 100, 100, 0.6);\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px; color: #FB0000;'
                    : 'background: rgba(191, 225, 255, 0.4);\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'color: #007CC2;\n' +
                      'border-radius: 0px;'
                "
                class="text-center headerC"
              >
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    h-100
                    iconFather
                  "
                  @click="openListMotivesModal(3, tableItem.mkt_id_flyer)"
                >
                  <span>{{ tableItem.sn_mob_filter_inc }}</span
                  ><tabler-icon
                    :style="
                      isDarkSkin
                        ? tableItem.sn_mob_filter_inc > 0
                          ? 'color: rgb(227 129 129)'
                          : 'color: rgb(151 211 245)'
                        : tableItem.sn_mob_filter_inc > 0
                        ? 'color: #FF0000'
                        : 'color: #007CC2'
                    "
                    style="margin-left: 0.2rem"
                    :icon="
                      tableItem.sn_mob_filter_inc > 0
                        ? 'AlertCircleIcon'
                        : 'CircleCheckIcon'
                    "
                    class="cursor-pointer iconChild"
                    size="16"
                    @click="openListMotivesModal(3, tableItem.mkt_id_flyer)"
                  />
                </div>
              </b-td>
              <b-td style="height: 63px; width: 120px" class="text-center">
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                  style="text-align: center; font-size: 15px"
                >
                  {{ tableItem.sn_app_filter }}
                </div>
              </b-td>
              <!--            filter -->
              <b-td style="height: 63px; width: 120px" class="text-center">
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                  style="text-align: center; font-size: 15px"
                  @click="openListMotivesModal(7, tableItem.mkt_id_flyer)"
                >
                  {{ tableItem.fil_no_answer_call }}
                </div>
              </b-td>
              <b-td
                style="height: 63px;  width: 120px  font-weight: bolder"
                :style="
                  isDarkSkin
                    ? tableItem.sn_filter_inc_seller > 0
                      ? 'background: rgba(255, 100, 100, 0.6);\n' +
                        'border: 1px solid rgb(179 172 172 / 59%);\n' +
                        'border-radius: 0px; '
                      : 'background: rgba(191, 225, 255, 0.4);\n' +
                        'border: 1px solid rgb(179 172 172 / 59%);\n' +
                        'border-radius: 0px;'
                    : tableItem.sn_filter_inc_seller > 0
                    ? 'background: rgba(255, 100, 100, 0.6);\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px; color: #FB0000;'
                    : 'background: rgba(191, 225, 255, 0.4);\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'color: #007CC2;\n' +
                      'border-radius: 0px;'
                "
                class="text-center headerC"
              >
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    h-100
                    iconFather
                  "
                  @click="openListMotivesModal(4, tableItem.mkt_id_flyer)"
                >
                  <span>{{ tableItem.sn_filter_inc_seller }}</span
                  ><tabler-icon
                    :style="
                      isDarkSkin
                        ? tableItem.sn_filter_inc_seller > 0
                          ? 'color: rgb(227 129 129)'
                          : 'color: rgb(151 211 245)'
                        : tableItem.sn_filter_inc_seller > 0
                        ? 'color: #FF0000'
                        : 'color: #007CC2'
                    "
                    style="margin-left: 0.2rem"
                    :icon="
                      tableItem.sn_filter_inc_seller > 0
                        ? 'AlertCircleIcon'
                        : 'CircleCheckIcon'
                    "
                    class="cursor-pointer iconChild"
                    size="16"
                    @click="openListMotivesModal(4, tableItem.mkt_id_flyer)"
                  />
                </div>
              </b-td>
              <b-td style="height: 63px; width: 120px" class="text-center">
                <div
                  v-if="index == program.tableData.length - 1"
                  class="d-flex align-items-center justify-content-center h-100"
                  style="text-align: center; font-size: 15px"
                >
                  <span
                    v-b-tooltip.hover.bottom="'From MM'"
                    class="
                      d-flex
                      align-items-center
                      justify-content-center
                      h-100
                    "
                    style="width: 50% !important; height: 100% !important"
                  >
                    {{ tableItem.fil_app_seller }}</span
                  >
                  <span
                    v-b-tooltip.hover.bottom="'From Filter'"
                    class="
                      d-flex
                      align-items-center
                      justify-content-center
                      h-100
                    "
                    style="
                      width: 50% !important;
                      height: 100% !important;
                      border-left: 1px solid !important;
                    "
                  >
                    {{ tableItem.fil_app_from_seller_reco }}</span
                  >
                </div>
                <div
                  v-else
                  class="d-flex align-items-center justify-content-center h-100"
                  style="text-align: center; font-size: 15px"
                >
                  {{ tableItem.fil_app_seller }}
                  <!-- process-sale/get-sales-by-flyer-id -->
                </div>
              </b-td>
              <!--            seller -->
              <b-td style="height: 63px; width: 120px" class="text-center">
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                  style="text-align: center; font-size: 15px"
                >
                  {{ tableItem.fil_app_seller_reco }}
                </div>
              </b-td>
              <b-td
                style="height: 63px; width: 140px"
                class="text-center cursor-pointer"
              >
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                  v-if="index == program.tableData.length - 1"
                  style="text-align: center; font-size: 15px"
                >
                  <span
                    v-b-tooltip.hover.bottom="'From MM or Filter'"
                    :class="tableItem.sel_sale > 0 ? 'text-primary' : ''"
                    class="
                      d-flex
                      align-items-center
                      justify-content-center
                      h-100
                    "
                    @click="openListSalesModal(tableItem.mkt_id_flyer)"
                    style="width: 50% !important; height: 100% !important"
                  >
                    {{ tableItem.sel_sale }}</span
                  >
                  <span
                    v-b-tooltip.hover.bottom="'From Seller'"
                    :class="tableItem.sel_sale_reco > 0 ? 'text-primary' : ''"
                    class="
                      d-flex
                      align-items-center
                      justify-content-center
                      h-100
                    "
                    style="
                      width: 50% !important;
                      height: 100% !important;
                      border-left: 1px solid !important;
                    "
                    @click="openListSalesModalRecovery(tableItem.mkt_id_flyer)"
                  >
                    {{ tableItem.sel_sale_reco }}</span
                  >

                  <!-- process-sale/get-sales-by-flyer-id -->
                </div>
                <div
                  v-else
                  @click="openListSalesModal(tableItem.mkt_id_flyer)"
                  class="d-flex align-items-center justify-content-center h-100"
                  :class="tableItem.sel_sale > 0 ? 'text-primary' : ''"
                  style="text-align: center; font-size: 15px"
                >
                  {{ tableItem.sel_sale }}
                  <!-- process-sale/get-sales-by-flyer-id -->
                </div>
              </b-td>
              <b-td style="height: 63px; width: 120px">
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                  style="text-align: center; font-size: 15px"
                  @click="openListMotivesModal(6, tableItem.mkt_id_flyer)"
                >
                  {{ tableItem.fil_seller_sales_inc_no_answer }}
                </div>
              </b-td>
              <b-td
                style="height: 63px;  width: 120px  font-weight: bolder"
                :style="
                  isDarkSkin
                    ? tableItem.fil_seller_sales_inc > 0
                      ? 'background: rgba(255, 100, 100, 0.6);\n' +
                        'border: 1px solid rgb(179 172 172 / 59%);\n' +
                        'border-radius: 0px; '
                      : 'background: rgba(191, 225, 255, 0.4);\n' +
                        'border: 1px solid rgb(179 172 172 / 59%);\n' +
                        'border-radius: 0px;'
                    : tableItem.fil_seller_sales_inc > 0
                    ? 'background: rgba(255, 100, 100, 0.6);\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'border-radius: 0px; color: #FB0000;'
                    : 'background: rgba(191, 225, 255, 0.4);\n' +
                      'border: 1px solid rgb(179 172 172 / 59%);\n' +
                      'color: #007CC2;\n' +
                      'border-radius: 0px;'
                "
                class="text-center headerC"
              >
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    h-100
                    iconFather
                  "
                  @click="openListMotivesModal(5, tableItem.mkt_id_flyer)"
                >
                  <span>{{ tableItem.fil_seller_sales_inc }}</span
                  ><tabler-icon
                    :style="
                      isDarkSkin
                        ? tableItem.fil_seller_sales_inc > 0
                          ? 'color: rgb(227 129 129)'
                          : 'color: rgb(151 211 245)'
                        : tableItem.fil_seller_sales_inc > 0
                        ? 'color: #FF0000'
                        : 'color: #007CC2'
                    "
                    style="margin-left: 0.2rem"
                    :icon="
                      tableItem.fil_seller_sales_inc > 0
                        ? 'AlertCircleIcon'
                        : 'CircleCheckIcon'
                    "
                    class="cursor-pointer iconChild"
                    size="16"
                    @click="openListMotivesModal(5, tableItem.mkt_id_flyer)"
                  />
                </div>
              </b-td>
              <b-td
                v-if="moduleId === 2 || moduleId === 12"
                style="height: 63px; width: 120px"
                class="text-center"
              >
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                  style="text-align: center; font-size: 15px"
                >
                  $ {{ tableItem.sel_initial_payment | currency }}
                </div>
              </b-td>
              <b-td
                v-if="moduleId === 2 || moduleId === 12"
                style="height: 63px; width: 120px"
                class="text-center"
              >
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                  style="text-align: center; font-size: 15px"
                >
                  {{
                    tableItem.mkt_type_reply == 1 ||
                    tableItem.mkt_type_reply == 2 ||
                    tableItem.mkt_type_reply == 3
                      ? "-"
                      : tableItem.sel_roi
                      ? parseFloat(tableItem.sel_roi).toFixed(2)
                      : "0.00"
                  }}
                  %
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr>
              <b-td colspan="2"
                ><div
                  class="font-weight-bolder font-medium-1 h-100"
                  style="text-align: center"
                >
                  Total
                </div>
              </b-td>
              <b-td
                v-if="moduleId === 2 || moduleId === 12"
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white"
              >
                <div class="k-font-weight-bold">
                  $ {{ program.sn_investment_total | currency }}
                </div></b-td
              >
              <b-td
                v-if="moduleId === 2 || moduleId === 12"
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>$ {{ program.sn_cpa_average | currency }}</div></b-td
              >
              <b-td
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>
                  {{ program.mkt_replies_total }}
                </div>
              </b-td>

              <b-td
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>
                  {{ program.unknown_replies_total }}
                </div>
              </b-td>
              <b-td
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>
                  {{ program.sn_multiply_total }}
                </div>
              </b-td>
              <b-td
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>
                  {{ program.sn_replies_total }}
                </div>
              </b-td>
              <b-td
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>
                  {{ program.re_ans_inc_total }}
                </div>
              </b-td>
              <b-td
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>
                  {{ program.sn_answers_total }}
                </div>
              </b-td>
              <b-td
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>
                  {{ program.ans_mob_inc_total }}
                </div>
              </b-td>
              <b-td
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>
                  {{ program.sn_mobiles_total }}
                </div>
              </b-td>
              <b-td
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>
                  {{ program.mob_filter_inc_total }}
                </div></b-td
              >
              <b-td
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>
                  {{ program.sn_app_filter_total }}
                </div>
              </b-td>
              <b-td
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>
                  {{ program.fil_no_answer_call_total }}
                </div>
              </b-td>
              <b-td
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>
                  {{ program.filter_seller_inc_total }}
                </div></b-td
              >
              <b-td
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>
                  {{ program.fil_app_seller_total }}
                </div>
              </b-td>
              <b-td
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>
                  {{ program.fil_app_seller_reco_total }}
                </div>
              </b-td>

              <b-td
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                >
                  {{
                    sumar(program.cat_sales_reco_total, program.cat_sales_total)
                  }}
                </div>
              </b-td>

              <b-td
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>{{ program.sale_inc_total_no_answer }}</div>
              </b-td>

              <b-td
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>
                  {{ program.sale_inc_total }}
                </div>
              </b-td>
              <b-td
                v-if="moduleId === 2 || moduleId === 12"
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>
                  $ {{ program.cat_sales_payment_total | currency }}
                </div></b-td
              >
              <b-td
                v-if="moduleId === 2 || moduleId === 12"
                :style="
                  isDarkSkin
                    ? 'background-color: #575757 !important'
                    : 'background-color: #888888 !important'
                "
                class="text-center text-white k-font-weight-bold"
              >
                <div>
                  {{
                    program.sale_roi_total
                      ? parseFloat(program.sale_roi_total).toFixed(2)
                      : "0.00"
                  }}
                  %
                </div></b-td
              >
            </b-tr>
            <b-tr>
              <b-td
                :colspan="!(moduleId === 2 || moduleId === 12) ? 13 : 17"
                class="div-span"
                style="border: none"
                :style="
                  isDarkSkin
                    ? 'background-color:#222222'
                    : 'background-color:#FFFFFF'
                "
              >
              </b-td>
              <b-td
                colspan="6"
                :style="defRoiProgram(program.roi)"
                class="text-center k-font-weight-bold"
              >
                <div>
                  <span>
                    <tabler-icon
                      :style="defineColorIcon(program.roi)"
                      size="24"
                      style="margin-bottom: 10px; margin-right: 10px !important"
                      :icon="
                        program.roi < 60 ? 'AlertCircleIcon' : 'CircleCheckIcon'
                      "
                  /></span>
                  <span
                    class="font-large-1 text-white"
                    style="margin-right: 5px"
                    >ROI
                  </span>
                  <span
                    class="font-large-1 text-white"
                    style="font-weight: bolder"
                    >{{
                      program.roi ? parseFloat(program.roi).toFixed(2) : "0.00"
                    }}
                    %</span
                  >
                </div>
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </div>
    </template>

    <list-motives-modal
      v-if="listMotivesModalOn"
      :type="typeToSend"
      :flyer-id="flyerId"
      :date-to="filters.to"
      :date-from="filters.from"
      :route="routeModule"
      @close="closeListMotivesModal"
    />
    <list-sales-modal
      v-if="listSalesModalOn"
      :flyer-id="flyerId"
      :date-to="filters.to"
      :date-from="filters.from"
      :route="routeModule"
      @close="closeListSalesModal"
    />
    <list-sales-modal-recovery
      v-if="listSalesModalOnRecovery"
      :flyer-id="flyerId"
      :date-to="filters.to"
      :date-from="filters.from"
      :route="routeModule"
      @close="closeListSalesModalRecovery"
    />
    <list-leads-by-value
      v-if="listLeadsByValue"
      :flyer-id="flyerId"
      :date-to="filters.to"
      :date-from="filters.from"
      :route="routeModule"
      @close="closeLeadsValueByFlyerId"
    />
  </div>
</template>

<script>
import ListLeadsByValue from "@/views/commons/components/roi-reports/modals/ListLeadsByValue";
import ListMotivesModal from "@/views/commons/components/roi-reports/modals/ListMotivesModal.vue";
import ListSalesModal from "@/views/commons/components/roi-reports/modals/ListSalesModal.vue";
import FiltersRoiSidebar from "@/views/commons/components/roi-reports/sidebars/FiltersRoiSidebar.vue";
import RoiReportsService from "@/views/commons/components/roi-reports/roi-reports.service";
import moment from "moment";
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import CoolLightBox from "vue-cool-lightbox";
import ListSalesModalRecovery from "@/views/commons/components/roi-reports/modals/ListSalesModalRecovery";

export default {
  components: {
    ListSalesModalRecovery,
    ListLeadsByValue,
    ListMotivesModal,
    ListSalesModal,
    FiltersRoiSidebar,
    vSelect,
    CoolLightBox,
  },
  data() {
    return {
      listLeadsByValue: false,
      minDate: new Date(moment().subtract(1, "year").format("YYYY-MM-DD")),
      maxDate: new Date(moment().add(1, "day").format("YYYY-MM-DD")),
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      showImage: null,
      mainProps: {
        center: false,
        fluidGrow: false,

        width: 100,

        block: false,

        fluid: true,
      },
      itemImage: [],
      totalRows: 10,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },

      prueba: 1,
      holaa: 2,
      numberOfItems: 6,
      listMotivesModalOn: false,
      listSalesModalOn: false,
      listSalesModalOnRecovery: false,
      filtersSidebarOn: false,
      typeToSend: null,
      filters: {
        from: moment().clone().startOf("month").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        program: null,
        advisor: "",
      },
      filtersData: {
        years: [],
        programs: [],
        advisors: [],
      },

      juanData: {
        programsData: [
          {
            programName: "",
            tableData: [
              {
                mkt_date: "",
              },
            ],
          },
        ],
        total_roi: 40,
      },
      items: [],
      flyerId: null,
      showMessage: false,
    };
  },
  computed: {
    roi_data() {
      let total = 0;
      let abr = 0;
      this.juanData.programsData.forEach((i) => {
        total += parseFloat(i.roi) ? parseFloat(i.roi) : 0;
      });
      abr = total / this.juanData.programsData.length;
      return abr ? abr.toFixed(2) : "0.00";
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      programs: "SocialNetworkGlobalStore/G_PROGRAMS",
    }),
    routeModule() {
      return this.$route.meta.route;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    this.addPreloader();
    try {
      await Promise.all([
        this.$store.dispatch("SocialNetworkGlobalStore/A_GET_PROGRAMS"),
      ]);
      this.filtersData.programs = this.programs;
      this.filtersData.programs.unshift({
        id: null,
        label: "All",
      });
      this.removePreloader();
    } catch (error) {
      this.removePreloader();
    }
    await this.getDataReports();
  },
  methods: {
    sumar(a, b) {
      return parseInt(a) + parseInt(b);
    },
    logo(asd) {
      switch (asd) {
        case "Boost Credit": // BC
          return "/images/logos/logo-bc.png";
        case "Business":
          return "/images/logos/logo-bu.png";
        case "Credit Experts": // CE
          return "/images/logos/logo-ce.png";
        case "Debt Solution": // DS
          return "/images/logos/logo-ds.png";
        case "Tax Research": // TR
          return "/images/logos/logo-tr.png";

        default:
          break;
      }
    },
    cropImage(id) {
      switch (id) {
        case "Boost Credit": // BC
          return {
            height: "31px",
            width: "70px",
            objectFit: "cover",
            objectPosition: "20% 10%",
          };
        case "Business":
          return {
            // BC
            height: "34px",
            width: "61px",
            objectFit: "cover",
            objectPosition: "20% 10%",
          };
        case "Credit Experts": // CE
          return {
            height: "32px",
            width: "34 px",
            objectFit: "cover",
            objectPosition: "20% 10%",
          };
        case "Debt Solution": // DS
          return {
            height: "32px",
            width: "57px",
            objectFit: "cover",
            objectPosition: "20% 10%",
          };
        case "Tax Research": // TR
          return {
            height: "30px",
            width: "60px",
            objectFit: "cover",
            objectPosition: "20% 10%",
          };
        case 6:
          return {
            height: "31px",
            width: "50px",
            objectFit: "cover",
            objectPosition: "20% 10%",
          };
        default:
          break;
      }
    },
    async refresh() {
      await this.getDataReports();
    },

    async getDataReports() {
      this.showMessage = false;
      try {
        this.addPreloader();
        const data = await RoiReportsService.getDataReport({
          date_flyer_from: this.filters.from,
          date_flyer_to: this.filters.to,
          id_program: this.filters.program,
        });
        if (data.status === 200) {
          this.juanData.programsData = data.data;
          if (this.juanData.programsData.length == 0) {
            this.showMessage = true;
          } else {
            this.pushImage();
          }

          this.removePreloader();
        }
        return this.items;
      } catch (error) {
        this.removePreloader();
        throw error;
      }
    },

    pushImage() {
      const imageArray = [];
      for (let i = 0; i < this.juanData.programsData.length; i++) {
        for (
          let j = 0;
          j < this.juanData.programsData[i].tableData.length;
          j++
        ) {
          imageArray.push({
            title: this.juanData.programsData[i].tableData[j].mkt_flyer_name,
            src: this.juanData.programsData[i].tableData[j].mkt_flyer_route,
            thumb: this.juanData.programsData[i].tableData[j].mkt_flyer_link,
          });
        }
      }
      this.itemImage = imageArray;
    },
    openImage(index, key) {
      this.showImage = index;
      for (let i = 0; i < key; i++) {
        this.showImage += this.juanData.programsData[i].tableData.length;
      }
    },
    defineColorIcon(roi) {
      if (this.isDarkSkin) {
        if (roi < 60) {
          return "color:red !important;";
        }
        if (roi > 60 && roi < 80) {
          return "color:#007CC2 !important;";
        }
        return "color:darkgreen !important;";
      }
      if (this.isDarkSkin === false) {
        return "color: white !important";
      }
      return "";
      // return this.isDarkSkin ? (roi > 0 ? 'color: rgb(227 129 129)' : 'color: rgb(151 211 245)') : (roi > 0 ? 'color: #FF0000': 'color: rgb(186 230 255)')
    },
    defStylePercentRoi(roi) {
      if (this.isDarkSkin) {
        if (roi < 60) {
          return "background-color:red !important;  color: white;";
        }
        if (roi > 60 && roi < 80) {
          return "background-color:#007CC2 !important;  color: white;";
        }
        return "background-color:darkgreen !important;  color: white;";
      }
      if (this.isDarkSkin === false) {
        return "background-color:white !important;  color: black;";
      }
      return "";
    },
    defStyleTextRoi(roi) {
      if (this.isDarkSkin) {
        return "av-balance-text-black";
      }
      if (this.isDarkSkin === false) {
        if (roi < 60) {
          return "av-balance-text-red";
        }
        if (roi > 60 && roi < 80) {
          return "av-balance-text-blue";
        }
        return "av-balance-text-green";
      }
      return "";
    },
    defRoiProgram(roi) {
      if (!this.isDarkSkin) {
        if (roi < 60) {
          return "background-color: red !important";
        }
        if (roi > 60 && roi < 80) {
          return "background-color: #007CC2 !important";
        }
        return "background-color: darkgreen !important";
      }
      return "background-color:#222222 !important";
    },
    defStyleBorderRoi(roi) {
      if (this.isDarkSkin) {
        return "av-balance-border-black";
      }
      if (this.isDarkSkin === false) {
        if (roi < 60) {
          return "av-balance-border-red";
        }
        if (roi > 60 && roi < 80) {
          return "av-balance-border-blue";
        }
        return "av-balance-border-green";
      }
      return "";
    },
    closeListMotivesModal() {
      this.listMotivesModalOn = false;
    },
    closeListSalesModal() {
      this.listSalesModalOn = false;
    },
    closeListSalesModalRecovery() {
      this.listSalesModalOnRecovery = false;
    },
    openListMotivesModal(type, id) {
      this.listMotivesModalOn = true;
      this.typeToSend = type;
      this.flyerId = id;
    },
    openListSalesModal(id) {
      this.listSalesModalOn = true;
      this.flyerId = id;
    },
    openListSalesModalRecovery(id) {
      this.listSalesModalOnRecovery = true;
      this.flyerId = id;
    },

    openLeadsValueByFlyerId(id) {
      this.listLeadsByValue = true;
      this.flyerId = id;
    },
    closeLeadsValueByFlyerId() {
      this.listLeadsByValue = false;
    },
  },
};
</script>

<style scoped lang="scss">
.content-header .content-header-right button {
  border-color: #00cfe8 !important;
  background-color: #00cfe8 !important;
}

.content-header .content-header-right .b-dropdown button {
  border-color: #00cfe8 !important;
  background-color: #00cfe8 !important;
}

.av-balance-border-black {
  border-radius: 7px;
  width: auto;

  border: 1px solid #2a2a2a;
  margin-right: 3px;
}

.av-balance-border-blue {
  border-radius: 7px;
  width: auto;
  border: 1px solid #007cc2;
  margin-right: 3px;
}

.av-balance-border-green {
  border-radius: 7px;
  width: auto;
  border: 1px solid darkgreen;
  margin-right: 3px;
}

.av-balance-text-blue {
  height: 35px;
  color: white;
  font-weight: bolder;
  background-color: #007cc2;
  width: 130px;
  line-height: 10px;
  padding: 13px;
  margin: 0;
  font-size: 23px;
}

.av-balance-text-green {
  height: 35px;
  color: white;
  font-weight: bolder;
  background-color: darkgreen;
  width: 130px;
  line-height: 10px;
  padding: 13px;
  margin: 0;
  font-size: 23px;
}

.av-balance-text-blue-general {
  height: 35px;
  color: white;
  font-weight: bolder;
  background-color: #2a2a2a;
  width: 170px;
  line-height: 10px;
  padding: 13px;
  margin: 0;
  font-size: 23px;
}

.av-balance-text-red-general {
  height: 35px;
  color: white;
  font-weight: bolder;
  background-color: red;
  width: 130px;
  line-height: 10px;
  padding: 13px;
  margin: 0;
  font-size: 23px;
}

.av-balance-content-blue {
  height: 35px;
  color: white;
  background-color: #0099c8;
  width: 100px;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.av-balance-border-red {
  border-radius: 7px;
  width: auto;
  border: 1px solid red;
  margin-right: 3px;
}
.form-group-input2 {
  height: 30px;
  padding: 0.438rem 1rem;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
  margin: 10px;
}

.av-balance-text-red {
  height: 35px;
  color: white;
  font-weight: bolder;
  background-color: red;
  width: 130px;
  line-height: 10px;
  padding: 13px;
  margin: 0;
  font-size: 23px;
}

.av-balance-text-black {
  height: 35px;
  color: white;
  font-weight: bolder;
  background-color: #222222;
  width: 130px;
  line-height: 10px;
  padding: 13px;
  margin: 0;
  font-size: 23px;
}

.bc-dark {
  background-color: #222222 !important;
}

.av-balance-content-red {
  height: 30px;
  color: white;
  background-color: #e83b4c;
  padding: 3px;
  width: 100px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.headerC {
  height: 63px !important;
  font-weight: bolder !important;
  padding: 0px !important;
  width: 70px !important;
}

.iconFather {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 16px;
  .iconChild {
    position: absolute;
    bottom: 5px !important;
    right: 5px !important;
  }
}

.iconFatherC {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 16px;
  .iconChildC {
    position: absolute;
    bottom: 4px !important;
    left: 107px !important;
  }
}

.div-span {
  background: #f9f9f9;
  border: #f9f9f9;
}
.dark-layout {
  .div-span {
    background: #111113;
    border: transparent;
  }
}
.table-scroll-pere tfoot tr,
.table-scroll-pere tfoot th {
  position: sticky !important;
  bottom: 0 !important;
}
</style>
